<template>
  <footer class="footer">
    <div class="footer__container container">
      <div class="footer__top">
        <nav class="footer__nav">
          <div class="footer__nav-item">
            <h2 class="footer__title" v-t="'footer.products'" />
            <ul class="footer__nav-list one-col">
              <li>
                <a target="_blank" href="http://app.roobee.io/" v-t="'footer.app'" />
              </li>
              <li>
                <a target="_blank" href="http://roobee.finance/" v-t="'footer.finance'" />
              </li>
            </ul>
          </div>
          <div class="footer__nav-item">
            <h2 class="footer__title" v-t="'footer.company'" />
            <ul class="footer__nav-list">
              <li>
                <a target="_blank" href="/docs/privacy_policy.pdf" v-t="'footer.privacy'" />
              </li>
              <li>
                <a target="_blank" href="/docs/cookies_policy.pdf" v-t="'footer.cookies'" />
              </li>
              <li>
                <a target="_blank" href="/docs/website_use_disclaimers.pdf" v-t="'footer.disclaimers'" />
              </li>
              <li>
                <a target="_blank" href="/docs/terms_of_use.pdf" v-t="'footer.terms'" />
              </li>
              <li>
                <a target="_blank" href="/docs/rplatform_risk_disclosure_statement.pdf" v-t="'footer.rplatform'" />
              </li>
            </ul>
          </div>
        </nav>
        <div class="footer__socials-wrapper">
          <h2 class="footer__title" v-t="'footer.socials'" />
          <ul class="footer__socials">
            <li>
              <a target="_blank" href="https://t.me/roobee_invest">
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.915 16.308c6.284-2.765 14.416-6.135 15.538-6.602 2.945-1.22 3.848-.987 3.398 1.717-.324 1.944-1.257 8.378-2.001 12.383-.442 2.375-1.432 2.657-2.99 1.63-.749-.495-4.53-2.995-5.35-3.582-.749-.535-1.782-1.179-.486-2.446.46-.452 3.483-3.337 5.837-5.583.309-.295-.079-.78-.435-.543a2748.796 2748.796 0 00-8.133 5.406c-.846.574-1.659.838-3.117.419-1.102-.317-2.179-.694-2.598-.838-1.613-.554-1.23-1.272.337-1.961z" fill="#636367"/></svg>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.facebook.com/roobee.invest/">
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16 5.5c-6.075 0-11 4.925-11 11 0 5.515 4.063 10.069 9.355 10.864v-7.949h-2.721v-2.891h2.721V14.6c0-3.186 1.552-4.584 4.2-4.584 1.268 0 1.939.095 2.256.137v2.524h-1.806c-1.124 0-1.516 1.066-1.516 2.267v1.58h3.293l-.446 2.891h-2.847v7.972C22.858 26.66 27 22.07 27 16.5c0-6.075-4.925-11-11-11z" fill="#636367"/></svg>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.reddit.com/user/Roobee_io">
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M17.76 6.82c-.927 0-2.01.727-2.173 3.974.138-.004.274-.014.413-.014.155 0 .314.008.467.014.105-1.951.57-3.094 1.293-3.094.31 0 .485.168.811.522.382.415.88.953 1.856 1.155a2.747 2.747 0 01-.027-.357c0-.186.019-.361.055-.536-.605-.165-.925-.514-1.238-.853-.348-.378-.749-.811-1.457-.811zm5.28.44c-.971 0-1.76.79-1.76 1.76s.789 1.76 1.76 1.76 1.76-.79 1.76-1.76-.789-1.76-1.76-1.76zM16 11.66c-5.338 0-9.68 2.719-9.68 6.6 0 3.88 4.341 7.04 9.68 7.04 5.338 0 9.68-3.16 9.68-7.04 0-3.881-4.342-6.6-9.68-6.6zm-7.7.412c-.643 0-1.253.263-1.719.73a2.392 2.392 0 00-.481 2.75c.665-1.288 1.81-2.387 3.286-3.205a2.413 2.413 0 00-1.086-.275zm15.4 0c-.38 0-.751.105-1.086.275 1.476.818 2.62 1.917 3.286 3.204a2.392 2.392 0 00-.481-2.75 2.426 2.426 0 00-1.72-.729zM12.48 15.62a1.32 1.32 0 110 2.64 1.32 1.32 0 010-2.64zm7.04 0a1.32 1.32 0 110 2.64 1.32 1.32 0 010-2.64zm-7.453 4.84c.11.019.219.08.29.179.046.065 1.077 1.443 3.643 1.443 2.6 0 3.633-1.416 3.644-1.43.139-.197.42-.25.618-.11a.43.43 0 01.097.605c-.052.074-1.307 1.815-4.36 1.815-3.053 0-4.306-1.74-4.358-1.815a.428.428 0 01.096-.605.45.45 0 01.33-.082z" fill="#636367"/></svg>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://medium.com/@roobee_invest">
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.465 11.175c.033-.32-.05-.569-.247-.746L5.246 7.873V7.5h6.113l4.683 11.13L20.183 7.5H26v.373L24.324 9.63c-.132.107-.18.266-.148.48v12.78c-.033.214.017.373.148.48l1.627 1.757v.373h-8.233v-.373l1.726-1.757c.065-.107.098-.178.098-.213a.796.796 0 00.05-.32V12.506L14.858 25.5h-.64L8.745 12.506v8.68c-.065.39.033.728.296 1.012l2.219 2.876v.426H5v-.426l2.169-2.876c.263-.284.362-.621.296-1.012V11.175z" fill="#636367"/></svg>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://twitter.com/Roobee_invest">
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M27 9.443c-.824.365-1.65.64-2.567.734.916-.552 1.65-1.468 2.016-2.478-.917.551-1.834.916-2.84 1.103-.917-.917-2.109-1.468-3.39-1.468a4.498 4.498 0 00-4.495 4.49c0 .369 0 .734.093 1.01-3.76-.183-7.057-1.927-9.26-4.677-.458.645-.64 1.468-.64 2.292 0 1.56.823 2.936 2.016 3.76-.734-.094-1.465-.276-2.016-.552v.093c0 2.199 1.557 4.032 3.573 4.4-.365.09-.73.184-1.189.184-.275 0-.551 0-.827-.094.552 1.834 2.202 3.12 4.218 3.12-1.557 1.192-3.484 1.922-5.593 1.922-.365 0-.734 0-1.099-.09a12.843 12.843 0 006.875 2.017c8.343 0 12.833-6.875 12.833-12.834v-.551c.917-.641 1.651-1.465 2.292-2.381z" fill="#636367"/></svg>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.instagram.com/roobee_invest/">
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11.66 6.5A5.672 5.672 0 006 12.16v8.186C6 23.457 8.543 26 11.66 26h8.186c3.111 0 5.654-2.543 5.654-5.66v-8.18a5.672 5.672 0 00-5.66-5.66h-8.18zm0 1.5h8.18A4.146 4.146 0 0124 12.16v8.18a4.145 4.145 0 01-4.154 4.16H11.66a4.145 4.145 0 01-4.16-4.154V12.16A4.146 4.146 0 0111.66 8zm9.09 2.5a.748.748 0 00-.75.75c0 .416.334.75.75.75s.75-.334.75-.75a.748.748 0 00-.75-.75zm-5 .5a5.264 5.264 0 00-5.25 5.25 5.264 5.264 0 005.25 5.25A5.264 5.264 0 0021 16.25 5.264 5.264 0 0015.75 11zm0 1.5c2.08 0 3.75 1.67 3.75 3.75S17.83 20 15.75 20 12 18.33 12 16.25s1.67-3.75 3.75-3.75z" fill="#636367" stroke="#636367" stroke-width=".2"/></svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer__bottom">
        <svg width="88" height="17" viewBox="0 0 88 17" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'footer__logo'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.084 7.286V4.452H0v12.346h3.084v-6.072l6.374-3.845-1.44-2.429-4.934 2.834zM18.3 13.762c-1.646 0-3.085-1.417-3.085-3.238 0-1.822 1.44-3.239 3.084-3.239 1.645 0 3.084 1.417 3.084 3.239.206 1.821-1.233 3.238-3.084 3.238zm0-9.31c-3.29 0-6.17 2.833-6.17 6.274 0 3.44 2.674 6.274 6.17 6.274 3.289 0 6.167-2.834 6.167-6.274 0-3.643-2.672-6.274-6.168-6.274zM34.13 13.762c-1.644 0-3.084-1.417-3.084-3.238 0-1.822 1.44-3.238 3.085-3.238 1.644 0 3.084 1.416 3.084 3.238.205 1.821-1.234 3.238-3.084 3.238zm0-9.31c-3.29 0-6.168 2.834-6.168 6.274S30.635 17 34.131 17c3.495 0 6.168-2.833 6.168-6.274 0-3.643-2.673-6.274-6.168-6.274zM49.963 13.762c-1.645 0-3.084-1.417-3.084-3.238 0-1.822 1.439-3.238 3.084-3.238s3.084 1.416 3.084 3.238c0 1.821-1.234 3.238-3.084 3.238zm0-9.31c-1.234 0-2.262.405-3.084.81V0H44v10.524c0 3.44 2.673 6.274 6.168 6.274 3.29 0 6.169-2.834 6.169-6.274-.206-3.44-2.879-6.072-6.374-6.072zM62.917 9.31c.41-1.417 1.439-2.024 2.878-2.024 1.44 0 2.673.607 2.879 2.023h-5.757zM66 4.451c-1.85 0-3.496.607-4.524 1.822-1.028 1.214-1.645 2.63-1.645 4.452 0 1.62.617 3.238 1.645 4.453C62.711 16.393 64.356 17 66.001 17c1.85 0 3.495-.607 4.523-1.619l.206-.202-2.056-2.024-.206.202c-.617.607-1.44.81-2.262.81-1.85 0-2.878-1.215-3.084-2.024h8.841v-.203-1.416c0-3.643-2.467-6.072-5.962-6.072zM78.953 9.31c.411-1.417 1.44-2.024 2.879-2.024s2.673.607 2.878 2.023h-5.757zm3.084-4.858c-1.85 0-3.495.607-4.523 1.822-1.028 1.214-1.645 2.63-1.645 4.452 0 1.62.617 3.238 1.645 4.453C78.748 16.393 80.393 17 82.037 17c1.85 0 3.496-.607 4.524-1.619l.205-.202-2.056-2.024-.205.202c-.617.607-1.44.81-2.262.81-1.85 0-2.879-1.215-3.084-2.024H88v-.203-1.416c0-3.643-2.467-6.072-5.963-6.072z" fill="#000"/></svg>
        <p class="footer__text" v-html="$t('footer.text')" />
        <span class="footer__copyright">{{$t('footer.copyright',{year: moment().format('YYYY')})}}</span>
      </div>
    </div>
  </footer>
</template>

<script>
import moment from "moment";

export default {
  name: "Footer",
  methods: {
    moment
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/elements/footer';
</style>